import React, { useState } from "react"
import { Form } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useDispatch } from "react-redux"
import { withRouter, Link } from "react-router-dom"

import logoImage from "../../assets/images/signin/Casita-WebLogo.png"
import { TextInput } from "../../components/TextInput"
import { Button } from "../../components/Button"
import { APP_ROUTES } from "../../helpers/routeHelpers"
import { login } from "store/actions/authActions"
import { withAuthentication } from "hoc"

const Login = props => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .trim()
                .email("Please enter a correct email address")
                .min(
                    6,
                    "Please enter an email address between 6 and 256 characters"
                )
                .max(
                    256,
                    "Please enter an email address between 6 and 256 characters"
                )
                .required("Please enter your email address"),
            password: Yup.string()
                .trim()
                .required("Please enter your password"),
        }),
        onSubmit: async ({ email, password }) => {
            try {
                setLoading(true)
                const res = await dispatch(
                    login({
                        Email: email,
                        Password: password,
                        Url: "https://qa-app.casita.kodefuse.com",
                    })
                )
            } catch (err) {
            } finally {
                setLoading(false)
            }
        },
    })

    return (
        <div className="px-0 ">
            <div className="limiter">
                <div className="container-login100 ">
                    <div className="left-img-container login100-more d-none d-sm-block">
                        <div className="mx-auto p-5 left-img-inner-con">
                            <div className="left-img-div">
                                <img src={logoImage} />
                            </div>
                        </div>
                    </div>
                    <div className="wrap-login100 p-5 p-md-5 p-sm-2">
                        <Form
                            className="form-horizontal"
                            onSubmit={e => {
                                e.preventDefault()
                                validation.handleSubmit()
                                return false
                            }}
                        >
                            <div className="mb-3 right-side-con">
                                <h3 className="mb-4">Sign in</h3>
                                <TextInput
                                    inputClass="signin-inputs"
                                    className="remove-validation-icons "
                                    label="Email address"
                                    name="email"
                                    placeholder="carlos.carrillo@gmail.com"
                                    type="email"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.email || ""}
                                    invalid={
                                        validation.touched.email &&
                                        !!validation.errors.email
                                    }
                                    valid={
                                        validation.touched.email &&
                                        !validation.errors.email
                                    }
                                />
                                {validation.touched.email &&
                                    !!validation.errors.email && (
                                        <div className="form-input-error">
                                            {validation.errors.email}
                                        </div>
                                    )}
                            </div>
                            <div className="mb-3">
                                <TextInput
                                    withIcon={true}
                                    inputClass="signin-inputs"
                                    className="d-flex flex-column remove-validation-icons"
                                    label="Your password"
                                    name="password"
                                    placeholder="*************"
                                    type="password"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    // value={validation.values.password || ""}
                                    invalid={
                                        validation.touched.password &&
                                        !!validation.errors.password
                                    }
                                    valid={
                                        validation.touched.password &&
                                        !validation.errors.password
                                    }
                                />
                                {validation.touched.password &&
                                    !!validation.errors.password && (
                                        <div className="form-input-error">
                                            {validation.errors.password}
                                        </div>
                                    )}
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="form-check ps-0">
                                    <Link
                                        to={APP_ROUTES.FORGET_PASSWORD}
                                        className="sign-in-forgetpass"
                                    >
                                        Forgot your password?
                                    </Link>
                                </div>
                            </div>
                            <div className="mt-3 sign-in-btn">
                                <Button
                                    type="submit"
                                    title="Sign In"
                                    className="gt-btn-grad-primary"
                                    isLoading={loading}
                                />
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withAuthentication(withRouter(Login))
